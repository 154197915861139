import './assets/scss/main.scss'
import "vue-toastification/dist/index.css"
import 'material-icons/iconfont/material-icons.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "./tailwind.css"
// import "./assets/styles/index.css"
// import 'remixicon/fonts/remixicon.css';
// import Element from "element-ui"
// import "element-ui/lib/theme-chalk/index.css"
import Toast from 'vue-toastification'
import './plugins/filters'
import vuetify from "@/plugins/vuetify"
import Clipboard from 'v-clipboard'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import Vidle from 'v-idle'
import VueHtml2pdf from 'vue-html2pdf'
import VueSocialSharing from 'vue-social-sharing'
import Form from "vform";
import JsonExcel from 'vue-json-excel';
import CountryFlag from 'vue-country-flag'
import TruncateWord from "./components/TruncateWord";

Vue.config.productionTip = false

// Prevent duplicates of the same type of toast
const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    return false
  }
  return toast
};

//global
window._ = require('lodash');
window.moment = require('moment');
window.Form = Form;

Vue.component('icon-base');
Vue.use(VueTelInputVuetify, {
  vuetify
})
Vue.component('DownloadExcel', JsonExcel)
Vue.component('country-flag', CountryFlag);
Vue.component('truncate-word', TruncateWord);

Vue.use(Clipboard)
Vue.use(Vidle)
Vue.use(Toast, { filterBeforeCreate })
Vue.use(VueHtml2pdf)
Vue.use(VueSocialSharing)
// Vue.use(Element)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
