import {RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
import router from '../../router'

const PR = Repo.get('request'); //PR =  Public Request

const state = {
    user: {},
    token: null,
    referral_link: '',
    loan_balance: 0,
    withdrawal_limit: 0,
};

const getters = {
    user(state){
        return state.user //= JSON.parse(localStorage.getItem('user'));
    },
    referral_link(state){
        return state.referral_link //= JSON.parse(localStorage.getItem('referral_link'));
    },
    loan_balance(state) {
        return state.loan_balance //= JSON.parse(localStorage.getItem('loan_blanace'));
    },
    token(state) {
        return state.token
    },
    withdrawal_limit(state) {
        return state.withdrawal_limit
    },
};

const mutations = {
    users(state,data){
        state.user          = data.user;
        state.referral_link = data.referral_link;
        state.token         = data.access_token ? data.access_token : state.token;
        state.loan_balance  = data.loan_balance;
    },
    logout(state) {
        state.token = null;
        state.user = {};
        state.referral_link = '';
        state.loan_balance = 0;
        state.withdrawal_limit = 0;
    },
    withdrawal_limit(state,data) {
        state.withdrawal_limit = data
    }
};

const actions = {
    async register({}, {name, phone, password, email, ref_code, platform }) {
        phone = phone.replace(/\s/g, '');
        let payload = { name, phone, password, email, platform };
        return await PR.register(payload, ref_code);
    },

    async validateotp({}, { otp, user_id }) {
        let payload = { otp, user_id };
        return await PR.validateotp(payload);
    },

    async login({commit, dispatch},form) {
        const res = await PR.login(form);
        commit('users', res);
        dispatch('getData');

        return res
    },

    async getData({commit, dispatch}) {
        const res = await PR.getUserData();
        dispatch('getkyc', res.user.id);
        commit('users',res);

        return res;
    },

    async logout({commit}){
        const res  = await commit('logout');
        await router.push('/login');

        return res;
    },

    async forgotpassword({}, {email}) {
        return await PR.forgotpassword(email);
    },

    async verifyotp({}, { verification_otp }) {
        return PR.verifyotp(verification_otp);
    },

    async verifypasswordotp({}, { otp, email }) {
        return await PR.verifypasswordotp(otp, email);
    },

    async updateprofile({ commit }, { dob, sex, id }) {
        const res = await PR.updateprofile(dob, sex, id);
        commit('users', res);

        return res;
    },

    async wallet({}, { user_id }) {
        return await PR.wallet(user_id);
    },

    async loanbalance({}, { user_id }) {
        return await PR.loanbalance(user_id);
    },

    async fundwallet({}, { amount, reference, user_id }) {
        return await PR.fundwallet(amount, reference, user_id);
    },

    async fundwalletmonnify({}, { amount, reference, user_id }) {
        return await PR.fundwalletmonnify(amount, reference, user_id);
    },

    async uploadavatar({ commit }, formData) {
        const res = await PR.uploadavatar(formData);
        commit('users', res);

        return res;
    },

    async resetpassword({}, { email, password, password_confirmation }) {
        return await PR.resetpassword(email, password, password_confirmation);
    },

    async changepassword({}, form) {
        return await PR.changepassword(form);
    },

    async resolvewallet({}, { user_id }) {
        return await PR.resolvewallet(user_id);
    },

    async createkyc({}, formData) {
        return  await PR.createkyc(formData);
    },

    async updatekyc({}, formData) {
        return await PR.updatekyc(formData);
    },

    async getkyc({ commit }, user_id) {
        const res = await PR.getkyc(user_id);
        commit('withdrawal_limit', res.data.user.withdrawal_limit);

        return res;
    },

    async kycstates() {
        return await PR.kycstates();
    },

    async kyclgas() {
        return await PR.kyclgas();
    },

    async kyccountries() {
        return await PR.kyccountries();
    },

    async accounttype({}, account_type_id) {
        return await PR.accounttype(account_type_id);
    },

    async getidtypes() {
        return await PR.idcardtypes();
    },

    async kycpercentage({}, kyc_id) {
        return await PR.kycpercentage(kyc_id);
    },

    async kycstatus({}, user_id) {
        return await PR.kycstatus(user_id);
    },

    async kyceditrequest({}, user_id) {
        return await PR.kyceditrequest(user_id);
    },

    async addbvn({}, { bvn, id }) {
        let payload = { bvn, id };
        return await PR.addbvn(payload);
    },

    async transactionhistory({}, user_id) {
        let payload = user_id;
        return await PR.transactionhistory(payload);
    },

    async referrals({}, user_id) {
        return await PR.referrals(user_id);
    },

    async secretquestion({}, form) {
        return await PR.secretquestion(form);
    },

    async secretanswer({}, payload) {
        return await PR.secretanswer(payload);
    },

    async transaction_details() {
        return await PR.transactions_details();
    },

    async filter_transactions({}, payload) {
        return await PR.getFilteredTransactions(payload);
    }
};

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}