import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Public Request
import router from '../../router'

const state = {}

const mutations = {}

const getters = {}

const actions = {
  async orderphysical({ commit }, { name, phone_number, address, state, lga }) {
    const res = await PR.orderphysical( name, phone_number, address, state, lga )

    return res
  },
  async ordervirtualcard({ commit }, { name, currency, address, amount, card_type }) {
    const res = await PR.ordervirtualcard( name, currency, address, amount, card_type)

    return res
  },
}


export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}