import guest from '../../router/middleware/guest'

const publicRoutes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./login'),
        meta: {
            middleware: [ guest ],
            layout: () => import('../../layouts/authentication'),
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('./register'),
        meta: {
            middleware: [ guest ],
            layout: () => import('../../layouts/authentication'),
        }
    },
    {
        path: '/register/:ref_code',
        name: 'Ref_link',
        component: () => import('./register'),
        meta: {
            middleware: [ guest ],
            layout: () => import('../../layouts/authentication')
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('./forgot-password'),
        meta: {
            middleware: [ guest ],
            layout: () => import('../../layouts/authentication'),
        }
    }
]

export default publicRoutes