import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Public Request
import router from '../../router'

const state = {
  business: {},
  businesses: [],
  businessCount: 0,
  guarantors: [],
  guarantor: {},
  businessAccount: {},
};
const mutations = {
  storeBusinesses: (state, payload) => {
    state.businesses = payload.data;
    state.businessCount = payload.total;
  },
  storeBusiness: (state, payload) => state.business = payload,
  storeGuarantors: (state, payload) => state.guarantors = payload,
  storeGuarantor: (state, payload) => state.guarantor = payload,
  storeBusinessAccount: (state, payload) => state.businessAccount = payload,
};

const getters = {
  business: state => state.business,
  businesses: state => state.businesses,
  businessCount: state => state.businessCount,
  guarantors: state => state.guarantors,
  guarantor: state => state.guarantor,
  businessAccount: state => state.businessAccount,
};

const actions = {

  /////////////////////synchronous actions/////////////////////////
  showBusiness({commit}, id) {
    PR.singlebusiness(id).then(res => {
      commit('storeBusiness', res.data);
    }).catch(err => {
      this._vm.$toast.error(err.response.data.message);
    })
  },
  allGuarantors({commit}, business_id) {
    PR.getBusinessGuarantors(business_id).then(res => {
      commit('storeGuarantors', res.data);
    }).catch(err => {
      this._vm.$toast.error(err.response.data.message);
    })
  },
  deleteGuarantor({commit}, id) {
    PR.removeBusinessGuarantor(id).then(res => {
      commit('storeGuarantors', res.data);
    }).catch(err => {
      this._vm.$toast.error(err.response.data.message);
    })
  },
  createGuarantor({}, data) {
    return PR.storeBusinessGuarantor(data);
  },
  showBusinessAccount({}, business_id) {
    return PR.getBusinessAccount(business_id);
  },
  createBusinessAccount({}, data) {
    return PR.createBusinessAccount(data);
  },
  updateBusinessAccount({}, {business_id, data}) {
    return PR.updateBusinessAccount(business_id, data);
  },
  /////////////////////end of synchronous actions//////////////////

  async create({ commit },{user_id, category,name, location, country, state, city}) {
    const data = await PR.create(user_id, category,name, location, country, state, city)

    return data
  },

  async mybusinesses() {
    const data = await PR.mybusinesses()

    return data
  },

  async singlebusiness({}, {id}) {
    return await PR.singlebusiness(id)
  },

  async bizbeneficiaries({},{business_id}) {
    return await PR.bizbeneficiaries(business_id)
  },

  async getbusinesstransfer({}, {id}) {
    return await PR.getbusinesstransfer(id)
  },

  async getbusinesstv({}, {id}) {
    return await PR.getbusinesstv(id)
  },

  async getbusinessairtime({}, {id}) {
    return await PR.getbusinessairtime(id)
  },

  async getbusinessdata({}, {id}) {
    return await PR.getbusinessdata(id)
  },

  async getbusinesspower({}, {id}) {
    return await PR.getbusinesspower(id)
  },

  async getbusinesssenttransfer({}, {id}) {
    return await PR.getbusinesssenttransfer(id)
  },

  async getbusinessrecievedtransfer({}, {id}) {
    return await PR.getbusinessrecievedtransfer(id)
  },

  async fundbusinesswallet({ commit }, { amount, reference, user_id, business_id }) {
    const data = await PR.fundbusinesswallet(
        amount,
        reference,
        user_id, business_id)
    return data
  },
  async fundbusinesswalletmonnify({ commit }, { amount, reference, user_id, business_id }) {
    const data = await PR.fundbusinesswallet(
        amount,
        reference,
        user_id, business_id)
    return data
  },

  async businesstransfertowallet({},{ amount, account_number, description, business_id, transaction_pin }) {
    const payload = { amount, account_number, description, business_id, transaction_pin }
    const data = await PR.businesstransfertowallet(payload)

    return data
  },
  
  async businesstransfertobank({},{ amount, recipient_account, business_id, bank_name, bank_code, receiver_account_name, transaction_pin }) {
    const payload = { amount, recipient_account, business_id, bank_name, bank_code, receiver_account_name, transaction_pin }
    const data = await PR.businesstransfertobank(payload)

    return data
  },

  async transactionhistory({}, { business_id }) {
    const res = await PR.businesstransactionhistory(business_id)

    return res
  },

  async savebenefit({}, { business_id, account_number, salary }) {
    let payload = { business_id, account_number, salary }
    const res = await PR.savebenefit(payload)

    return res
  },

  async wallettowallet(
    {},
    { business_id, user_id, pin, amount, account_number, description }
  ) {
    const data = await PR.wallettowallet(business_id, user_id,pin,amount,account_number,description)

    return data
  },

  async transferhistory({}, {id}) {
    const data = await PR.transferhistory(id)

    return data
  },

  async receivedhistory({}, {id}) {
    const data = await PR.receivedhistory(id)

    return data
  },

  async addBizOwner({}, { business_id, first_name, last_name, other_names, email, id_type, id_card, address, country, state, city, lga, phone, bvn }) {
    let payload = { business_id, first_name, last_name, other_names, email, id_type, id_card, address, country, state, city, lga, phone, bvn }
    const res = await PR.addBizOwner(payload)
    
    return res
  },

  async removeO({}, {id}) {
    const res =  await PR.removeO(id)

    return res
  },
  
  async updateO({}, { business_id, id, first_name, last_name, other_names, email, id_type, id_card, address, country, state, city, lga, phone, bvn }) {
    let payload = { business_id, id, first_name, last_name, other_names, email, id_type, id_card, address, country, state, city, lga, phone, bvn }
    const res =  await PR.updateO(payload)

    return res
  },

  async uploadbizkyc({}, formData) {
    const res = await PR.uploadbizkyc(formData)
  
    return res
  },

  async updatebizkyc({}, formData) {
    const res = await PR.updatebizkyc(formData)
  
    return res
  },

  async businesskyc({}, {business_id}) {
    const res = await PR.businesskyc(business_id)
    
    return res 
  },

  async setsecreteq({}, { business_id, question, answer }) {
    let payload = { business_id, question, answer }
    const res = await PR.setsecreteq(payload)

    return res
  },

  async setnewbizpin({}, { business_id, transaction_pin }) {
    let payload = { business_id, transaction_pin }
    const res = await PR.setnewbizpin(payload)

    return res    
  },

  async resetnewbizpin({}, { answer, business_id, transaction_pin, otp }) {
    let payload = { answer, business_id, transaction_pin, otp }
    const res = await PR.resetnewbizpin(payload)

    return res    
  },

  async deletebene({}, { id }) {
    const res = await PR.deletebene(id)
    
    return res
  },

  async editbene({}, { staff_id, salary, on_payroll, suspended, deactivated, bonus, tax, pay_cycle }) {
    let payload = { staff_id, salary, on_payroll, suspended, deactivated, bonus, tax, pay_cycle }
    const res = await PR.editbene(payload)
    
    return res
  },

  async editbiz({}, formData) {
    const res =  await PR.editbiz(formData)

    return res
  },

  async paystaffs({}, { business_id, recipients, staff_id, transaction_pin }) {
    let payload = { business_id, recipients, staff_id, transaction_pin }
    const res = await PR.paystaffs(payload)

    return res
  }
}


export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}