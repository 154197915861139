import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Public Request
import router from '../../router'

const state = {}

const mutations = {}

const getters = {}

const actions = {
  async createPersonal({},{ userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,type }) {
    let payload = {userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,type}
    const res = await PR.createPersonal(payload)

    return res
  },
  async createGroup( {}, { userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,num_of_members,access}) {
    const res = await PR.createGroup(userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,num_of_members,access)
    return res
  },
  async createAgent( {}, { user_id, admin_setup, amount, cycle, name, description, start_date, end_date, num_of_members }) {
    let payload = {user_id, admin_setup, amount, cycle, name, description, start_date, end_date, num_of_members}
    const data = await PR.createAgent( payload )

    return data
  },
  async fundpersonalsavingswithwallet({}, { user_id, account_id, amount }) {
    let payload = { user_id, account_id, amount }
    const res = PR.fundpersonalsavingswithwallet(payload)

    return res
  },
  async fundgroupsavingswithwallet({}, { user_id, account_id, payment_ref, save_card }) {
    let payload = { user_id, account_id, payment_ref, save_card }
    const res = await PR.fundgroupsavingswithwallet(payload)
    return res
  },
  async fundpersonalsavingswithcard({}, { user_id, account_id, payment_ref, save_card }) {
    let payload = { user_id, account_id, payment_ref, save_card }
    const res = await PR.fundpersonalsavingswithcard(payload)
    return res
  },
  async fundagentsavingswithwallet(
    { commit },
    { user_id, account_id, amount }
  ) {
    let token = JSON.parse(window.localStorage.getItem('transave')).auth.token
    const data = await this.$axios.$post(
      '/savings/agent/fund/wallet',
      {
        user_id,
        account_id,
        amount,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'dev-auth': process.env.NUXT_ENV_VUE_ACCESS_GRANT,
        },
      }
    )

    return data
  },
  async fundagentsavingswithcard(
    { commit },
    { user_id, agent_id, account_id, payment_ref, save_card }
  ) {
    let token = JSON.parse(window.localStorage.getItem('transave')).auth.token
    const data = await this.$axios.$post(
      '/savings/agent/agent_fund/card',
      {
        agent_id,
        user_id,
        account_id,
        payment_ref,
        save_card,
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
          'dev-auth': process.env.NUXT_ENV_VUE_ACCESS_GRANT,
        },
      }
    )
    return data
  },
  async allpersonalsavings({}, { user_id }) {
    const res = await PR.allpersonalsavings(user_id)

    return res
  },
  async allprivategroupsavings({}, { user_id }) {
    const res = await PR.allprivategroupsavings(user_id)

    return res
  },
  async allpublicgroupsavings({}) {
    const res = await PR.allpublicgroupsavings()

    return res
  },
  async invitefriendgroup({ commit }, { group_id, admin_id, phone_number }) {
    const res = await PR.invitefriendgroup( group_id, admin_id, phone_number)

    return res
  },
  async allagentsavings({}, { user_id }) {
    const data = await PR.allagentsavings(user_id)

    return data
  },
  async allgroupmembers({}, {groupId}) {
    const res = await PR.allgroupmembers(groupId)

    return res
  },
  async allagentgroupmembers({}, {groupId}) {
    const res = await PR.allagentgroupmembers(groupId)

    return res
  },
  async savingshistory({}, {groupId}) {
    const res = await PR.savingshistory(groupId)

    return res
  },
  async agentsavingshistory({}, {groupId}) {
    const res = await PR.agentsavingshistory(groupId)

    return res
  },
  async joinpublicgroup({}, {user_id, group_id}) {
    const res = await PR.joinpublicgroup(user_id,group_id)

    return res
  },
  async joinagentgroup({}, {user_id, group_id}) {
    const res = await PR.joinagentgroup(user_id,group_id)

    return res
  },
  async voteadmin({}, { user_id, disburse_to, disburse, account_id }) {
    let payload = { user_id, disburse_to, disburse, account_id }
    const res = await PR.voteadmin(payload)
    return res
  },
  async votedisburse({}, { user_id, disburse_to, disburse, account_id }) {
    let payload = { user_id, disburse_to, disburse, account_id }
    const res = await PR.votedisburse(payload)

    return res
  },
  async getDisbursementVoteOutcome({}, { id }){
    const res = await PR.getDisbursementVoteOutcome(id)
    return res
  },
  async getgrouprequests({}, { group_id }) {
    const res = await PR.getgrouprequests(group_id)

    return res
  },
  async getagentrequests({}, { group_id }) {
    const res = await PR.getagentrequests(group_id)

    return res
  },
  async acceptgrouprequest({}, { group_id, user_id, admin_id, accept_request }) {
    let payload = { group_id, user_id, admin_id, accept_request }
    const res = await PR.acceptgrouprequest(payload)

    return res
  },
  async acceptagentgrouprequest({}, { group_id, user_id, agent_id, accept_request }) {
    let payload = { group_id, user_id, agent_id, accept_request }
    const res = await PR.acceptagentgrouprequest(payload)

    return res
  },
  async getselected({}, { id }) {
    const res = await PR.getselected(id)

    return res
  },
  async withdrawpersonalsavings({}, { account_id, user_id, transaction_pin }) {
    let payload = { account_id, user_id, transaction_pin }
    const res = await PR.withdrawpersonalsavings(payload)
    return res
  },
  async extendpersonalsavings({}, { user_id, account_id, amount, duration, overall_amount, cycle, pin }) {
    let payload = { user_id, account_id, amount, duration, overall_amount, cycle, pin }
    const res = await PR.extendpersonalsavings(payload)
    return res
  },
  async quitpersonalsavings({}, { user_id, account_id, reason, explanation, transaction_pin }) {
    let payload = { user_id, account_id, reason, explanation, transaction_pin }
    const res = await PR.quitpersonalsavings(payload)
    return res
  }
  
}

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}