import Vue from 'vue'
import axios from "axios"
import axiosRetry from 'axios-retry'
import store from "../store/index"

axios.defaults.withCredentials = false;
axios.interceptors.response.use(response => response.data)
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (!error.response) {
        Vue.$toast.error('Network Error, Please check your network connection!')
    }
    if (error.response.status === 401 || error.response.data.message === 'Token is Expired') {
        store.dispatch('auth/logout')
    }
    return Promise.reject(error);
});
//Default setup for Authorization
axios.interceptors.request.use(function (config) {
    const token = store.state.auth.token;
    config.headers.Authorization =  `Bearer ${token}`;
    config.headers['dev-auth'] = process.env.VUE_APP_ENV_ACCESS_GRANT
    return config;
});

let url ='';
if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'){
    url = process.env.VUE_APP_ENV_STAGING
} else {
    url = process.env.VUE_APP_BASEURL
}


export default {

    ///////////////////// AUTH ////////////////
    register(payload, ref_code) {
        if (ref_code) {
            return axios.post(url + '/register/' + ref_code, payload)
        } else {
            return axios.post(url + '/register', payload)
        }
    },

    login(form) {
        return axios.post(url + '/login', form)
    },

    getUserData() {
        return axios.get(url + '/users/me');
    },

    wallet(user_id) {
        return axios.get(url + '/get_user_wallet_balance/' + user_id)
    },

    loanbalance(user_id) {
        return axios.get(url + '/get_user_loan_balance/' + user_id)
    },

    validateotp(payload) {
        return axios.post(url + '/verify-otp', payload)
    },

    forgotpassword(email) {
        return axios.post(url + '/forgot_password', { email })
    },

    verifyotp(verification_otp) {
        return axios.post(url + '/auth/verify/otp', { verification_otp })
    },

    verifypasswordotp(otp, email) {
        return axios.post(url + '/verify_password_token', { otp, email })
    },

    profile(email) {
        return axios.get(url + '/auth/profile/' + email)
    },

    updateprofile(dob, sex, id) {
        return axios.post(url + '/users/' + id, { dob, sex })
    },

    fundwallet(amount, reference, user_id) {
        return axios.post(url + '/fund_user_wallet/card', { amount, reference, user_id })
    },

    fundwalletmonnify(amount, reference, user_id) {
        return axios.post(url + '/fund_user_wallet/transfer', { amount, reference, user_id })
    },

    uploadavatar(formData) {
        return axios.post(url + '/users/' + formData.get('user_id'), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    resetpassword(email, password, password_confirmation) {
        return axios.post(url + '/password_reset', { email, password, password_confirmation })
    },

    changepassword(payload) {
        return axios.post(url + '/users/' + payload.id + '/password/change', payload)
    },

    resolvewallet(user_id) {
        return axios.post(url + '/resolvewallet', { user_id })
    },

    createkyc(formData) {
        return axios.post(url + '/users/kycs/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    updatekyc(formData) {
        return axios.post(url + '/users/kycs/' + formData.get('id') + '/update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    getkyc(user_id) {
        return axios.get(url + '/users/kycs/' + user_id)
    },

    kycstates() {
        return axios.get(url + '/general/states')
    },

    kyclgas() {
        return axios.get(url + '/general/lgas')
    },

    kyccountries() {
        return axios.get(url + '/general/countries')
    },

    accounttype(account_type_id) {
        return axios.get(url + '/general/account-types/' + account_type_id)
    },

    idcardtypes() {
        return axios.get(url + '/general/id-cards')
    },

    kycpercentage(kyc_id) {
        return axios.get(url + '/users/kycs/'+ kyc_id + '/percentage')
    },

    kycstatus(user_id) {
        return axios.get(url + '/users/kycs/'+ user_id + '/check')
    },

    kyceditrequest(user_id) {
        return axios.post(url + '/users/kycs/' + user_id + '/edit')
    },

    addbvn(payload) {
        return axios.post(url + '/verify_bvn', payload)
    },

    transactionhistory(payload) {
        return axios.get(url + '/transaction_history/' + payload)
    },

    referrals(payload) {
        return axios.get(url + '/referrals/' + payload)
    },

    secretquestion(payload) {
        return axios.post(url + '/secret_question_and_answer', payload)
    },

    secretanswer(payload) {
        return axios.get(url + '/secret_question_and_answer/' + payload)
    },

    transactions_details() {
        return axios.get(url + '/users/transactions/history');
    },

    getFilteredTransactions(payload) {
        return axios.get(url + '/users/transactions/history', {
            params: payload
        });
    },

    /////////////////// BILLS ///////////////////////////
    buyairtime(payload) {
        return axios.post(url + '/bills/airtime/request', payload)
    },

    buydata(payload) {
        return axios.post(url + '/bills/data/request', payload)
    },

    databundles(id) {
        return axios.get(url + '/bills/data/bundles/' + id)
    },

    verifycable(payload) {
        return axios.post(url + '/bills/tv/info', payload)
    },

    buycable(payload) {
        return axios.post(url + '/bills/tv/request', payload)
    },

    tvplans(id) {
        return axios.get(url + '/bills/tv/info/' + id)
    },

    verifypower(payload) {
        return axios.post(url + '/bills/power/meter-info', payload)
    },

    buypower(payload) {
        return axios.post(url + '/bills/power/request', payload)
    },

    states() {
        return axios.get(url + '/states')
    },

    power_transactions(user_id) {
        return axios.get(url + '/user/all/bill-transactions/' + user_id + '/power')
    },

    data_transactions(user_id) {
        return axios.get(url + '/user/all/bill-transactions/' + user_id + '/data')
    },

    airtime_transactions(user_id) {
        return axios.get(url + '/user/all/bill-transactions/' + user_id + '/airtime')
    },

    tv_transactions(user_id) {
        return axios.get(url + '/user/all/bill-transactions/' + user_id + '/tv')
    },

    ////////////////////////// Transfer ////////////////////////////
    getallbanks() {
        return axios.get(url + '/banks')
    },

    wallettowallet(payload) {
        return axios.post(url + '/wallet/transfer', payload)
    },

    resolvewalletid(payload) {
        return axios.get(url + '/verify_wallet_account_number/' + payload)
    },

    // beneficiaries(user_id) {
    //     return axios.get(url + '/beneficiaries/' + user_id)
    // },

    savebeneficiary(payload) {
        return axios.post(url + '/save/beneficiary', payload)
    },

    resolveaccount(payload) {
        return axios.post(url + '/verify_account_number', payload)
    },

    transfertobank(payload) {
        return axios.post(url + '/bank-transfer', payload)
    },

    settransactionpin( transaction_pin, user_id ) {
        return axios.post(url + '/set_transaction_pin', {transaction_pin, user_id })
    },

    updatetransactionpin(payload) {
        return axios.post(url + '/update_transaction_pin', payload)
    },

    multiplewalletstransfer(payload) {
        return axios.post(url + '/wallet/multiple_transfer', payload)
    },

    multiplebanktransfer(payload) {
        return axios.post(url + '/bank/multiple_transfer', payload)
    },

    sendchangepinotp(payload) {
        return axios.post(url + '/change_pin/get_otp', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        })
    },

    ////////////////////// SAVINGS ////////////////////////
    createPersonal(payload) {
        return axios.post(url + '/savings/personal/create', payload)
    },

    createGroup(userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,num_of_members,access) {
        return axios.post(url + '/savings/group/create', {userId,amount,cycle,name,duration,description,start_date,end_date,overall_amount,num_of_members,access})
    },

    createAgent(payload) {
        return axios.post(url + '/savings/agent/create', payload)
    },

    allpersonalsavings(user_id) {
        return axios.get(url + '/savings/personal/accounts/' + user_id )
    },

    allagentsavings(user_id) {
        return axios.get(url + '/savings/agent/all', {user_id})
    },

    allprivategroupsavings(user_id){
        return axios.get(url + '/savings/group/user_groups/' + user_id)
    },

    allpublicgroupsavings(){
        return axios.get(url + '/savings/group/all')
    },

    allgroupmembers(groupId) {
        return axios.get( url + '/savings/group/' + groupId + '/members')
    },

    allagentgroupmembers(groupId) {
        return axios.get( url + '/savings/agent/' + groupId + '/members')
    },

    savingshistory(groupId) {
        return axios.get( url + '/savings/group/account/history/' + groupId)
    },

    getselected(id) {
        return axios.get( url + '/savings/' + id )
    },

    agentsavingshistory(groupId) {
        return axios.get( url + '/savings/agent/' + groupId + '/history')
    },

    joinpublicgroup(user_id,group_id) {
        return axios.post(url + '/savings/group/join/request', {user_id,group_id})
    },

    joinagentgroup(user_id,group_id) {
        return axios.post(url + '/savings/agent/join/request', {user_id,group_id})
    },

    invitefriendgroup(group_id, admin_id, phone_number) {
        return axios.post(url + '/savings/group/add/phonenumber', {group_id, admin_id, phone_number})
    },

    voteadmin(payload) {
        return axios.post(url + '/savings/group/disburse/vote', payload)
    },
    votedisburse(payload) {
        return axios.post(url + '/savings/group/disburse/vote', payload)
    },
    getDisbursementVoteOutcome(id) {
        return axios.get(url + '/savings/group/vote_results/' + id)
    },
    getgrouprequests(group_id) {
        return axios.get(url + '/savings/group/requests/' + group_id)
    },

    getagentrequests(group_id) {
        return axios.get(url + '/savings/agent/requests/' + group_id)
    },

    acceptgrouprequest(payload) {
        return axios.post(url + '/savings/group/request/accept', payload)
    },

    acceptagentgrouprequest(payload) {
        return axios.post(url + '/savings/agent/request/accept', payload)
    },
    withdrawpersonalsavings(payload) {
        return axios.post(url + '/savings/personal/account/withdraw', payload)
    },
    extendpersonalsavings(payload) {
        return axios.post(url + '/savings/personal/extend', payload)
    },
    quitpersonalsavings(payload) {
        return axios.post(url + '/savings/personal/account/close', payload)
    },
    fundpersonalsavingswithwallet(payload) {
        return axios.post(url + '/savings/personal/fund/wallet', payload)
    },
    fundgroupsavingswithwallet(payload) {
        return axios.post(url + '/savings/group/fund/wallet', payload)
    },
    fundpersonalsavingswithcard(payload) {
        return axios.post(url + '/savings/personal/fund/card', payload)
    },
    fundgroupsavingswithcard(payload) {
        return axios.post(url + '/savings/group/fund/card', payload)
    },
    /////////////////////////// CARDS ////////////////////////////////

    orderphysical(name, phone_number, address, state, lga) {
        return axios.post(url + '/request-physicalcard', {name, phone_number, address, state, lga} )
    },

    ordervirtualcard(name, currency, address, amount, card_type) {
        return axios.post(url + '/request-virtuallcard', {name, currency, address, amount, card_type} )
    },

    ///////////////////////////// LOANS //////////////////////////////
    fetchUserLoans(user_id, filter, search, start, end, page) {
        return axios.get(`${url}/loans/${user_id}/user?filter=${filter}&search=${search}&start=${start}&end=${end}&page=${page}`);
    },
    getAllEducationalQualifications() {
        return axios.get(`${url}/general/educational-qualifications`);
    },
    getAllEmploymentStatuses() {
        return axios.get(`${url}/general/employment-statuses`);
    },
    getAllResidentialStatuses() {
        return axios.get(`${url}/general/residential-statuses`);
    },
    getAllMonthlyIncomes() {
        return axios.get(`${url}/general/monthly-incomes`);
    },
    getAllPaymentCards() {
        return axios.get(`${url}/general/payment-cards`);
    },
    createPaymentCard(data) {
        return axios.post(`${url}/payment-cards/create`, data);
    },
    getUserPaymentCards(owner_id) {
        return axios.get(`${url}/payment-cards/${owner_id}`);
    },
    cardBankList() {
        return axios.get(`${url}/cards/banks`);
    },
    bankByCode(code) {
        return axios.get(`${url}/cards/banks/${code}`);
    },
    createLoanAccount(data) {
        return axios.post(`${url}/loan-accounts/create`, data);
    },
    getLoanGuarantors(user_id) {
        return axios.get(`${url}/loan-accounts/${user_id}/guarantors`);
    },
    createLoanGuarantor(data) {
        return axios.post(`${url}/loan-accounts/guarantor`, data);
    },
    changeAccountIsComplete(id, data) {
        return axios.post(`${url}/loan-accounts/${id}/update`, data);
    },
    AccountOnboarding(id, data) {
        return axios.post(`${url}/loan-accounts/${id}/onboarded`, data);
    },
    deleteLoanGuarantor(id) {
        return axios.delete(`${url}/loan-accounts/${id}/guarantors/delete`);
    },
    showLoanAccount(user_id) {
        return axios.get(`${url}/loan-accounts/${user_id}`);
    },
    destroyLoan(id) {
        return axios.delete(`${url}/loans/${id}/delete`);
    },
    calculateLoanDetails(data) {
        return axios.post(`${url}/loans/calculate`, data);
    },
    createLoan(data) {
        return axios.post(`${url}/loans/create`, data);
    },
    initLoanCard(id, data) {
        return axios.post(`${url}/loans/${id}/initialize`, data);
    },
    loanCardCallback(id, reference) {
        return axios.get(`${url}/loans/${id}/callback?reference=${reference}`);
    },
    walletRepayLoan(id, data) {
        return axios.post(`${url}/loans/${id}/repay`, data);
    },
    cardRepayLoan(id, data) {
        return axios.post(`${url}/loans/${id}/card-repay`, data);
    },
    newCardRepayLoan(id, data) {
        return axios.post(`${url}/loans/${id}/new-repay`, data);
    },
    getAllIdCardTypes() {
        return axios.get(`${url}/general/id-cards`);
    },
    createLoanRequest(data) {
        return axios.post(`${url}/loan-requests/create`, data);
    },
    fetchUserLoanRequests(owner_id, search, start, end, page) {
        return axios.get(`${url}/loan-requests/${owner_id}/show?search=${search}&start=${start}&end=${end}&page=${page}`);
    },
    createUserRequest(data) {
        return axios.post(`${url}/user-requests/create`, data);
    },
    fetchUserAccountRequests(owner_id, search, start, end, page) {
        return axios.get(`${url}/user-requests/${owner_id}/show?search=${search}&start=${start}&end=${end}&page=${page}`);
    },

    ////////////////////////////////////BUSINESS LOANS//////////////////////////////////////////////////////////
    getBusinessGuarantors(business_id) {
        return axios.get(`${url}/business/loans/${business_id}/guarantors`);
    },
    storeBusinessGuarantor(data) {
        return axios.post(`${url}/business/loans/guarantors`, data);
    },
    removeBusinessGuarantor(id) {
        return axios.delete(`${url}/business/loans/${id}/guarantors`);
    },
    getBusinessAccount(business_id) {
        return axios.get(`${url}/business-kycs/${business_id}/show`);
    },
    createBusinessAccount(data) {
        return axios.post(`${url}/business-kycs/create`, data);
    },
    updateBusinessAccount(business_id, data) {
        return axios.post(`${url}/business-kycs/${business_id}/update`, data);
    },
    // apply(first_name,last_name,middlename,email,phone_number,bvn,nok,nok_contact,home_address,children,marital_status,
    //     emergency_contact_name,emergency_contact_number,state,lga,city,residential_status,employment_status,company_name,job_title,date_started,monthly_income,
    //     loan_amount,gender,educational_level){
    //     return axios.post(url + '/request-loan', {first_name,last_name,middlename,email,phone_number,bvn,nok,nok_contact,home_address,children,marital_status,
    //     emergency_contact_name,emergency_contact_number,state,lga,city,residential_status,employment_status,company_name,job_title,date_started,monthly_income,
    //     loan_amount,gender,educational_level})
    // },
    //
    // history(user_id) {
    //     return axios.get(url + '/get_user_loan_history/' + {user_id})
    // },

    /////////////////////////// BUSINESS /////////////////////////////

    create(user_id, category,name, location, country, state, city) {
        return axios.post(url + '/business/register', {user_id, category,name, location, country, state, city})
    },

    mybusinesses() {
        return axios.get(url + '/business/list')
    },

    singlebusiness(id) {
        return axios.get(url + '/business/' + id)
    },

    bizbeneficiaries(business_id) {
        return axios.get(url + '/business/beneficiaries/' + business_id)
    },
    
    getbusinesstransfer(id) {
        return axios.get(url + '/business/transfer_history/' + id)
    },
    
    getbusinesstv(id) {
        return axios.get(url + '/business/tv_history/' + id)
    },
    
    getbusinessairtime(id) {
        return axios.get(url + '/business/airtime_history/' + id)
    },
    
    getbusinessdata(id) {
        return axios.get(url + '/business/data_history/' + id)
    },
    
    getbusinesspower(id) {
        return axios.get(url + '/business/power_history/' + id)
    },
    
    getbusinesssenttransfer(id) {
        return axios.get(url + '/business/sent_transfer_history/' + id)
    },
    
    getbusinessrecievedtransfer(id) {
        return axios.get(url + '/business/received_transfer_history/' + id)
    },

    fundbusinesswallet(amount, reference, user_id, business_id){
        return axios.post(url + '/business/fund/card', {amount, reference, user_id, business_id})
    },

    fundbusinesswalletmonnify(amount, reference, user_id, business_id) {
        return axios.post(url + '/business/fund/transfer', {amount, reference, user_id, business_id})
    },

    businesstransfertowallet(payload) {
        return axios.post(url + '/business/wallet/transfer', payload)
    },

    businesstransfertobank(payload) {
        return axios.post(url + '/business/bank/transfer', payload)
    },

    businesstransactionhistory(business_id) {
        return axios.get(url + '/business/history/' + business_id)
    },

    savebenefit(payload) {
        return axios.post(url + '/business/save/beneficiary', payload)
    },

    deletebene(id) {
        return axios.delete(url + '/business/remove/beneficiary/' + id)
    },

    editbene(payload) {
        return axios.put(url + '/business/update/beneficiary/' + payload.staff_id, payload)
    },

    // wallettowallet(business_id, user_id, pin, amount, account_number, description) {
    //     return axios.post(url + '/business/wallet/transfer', {business_id, user_id, pin, amount, account_number, description})
    // },

    transferhistory(id) {
        return axios.get(url + '/business/transfer_history/' + {id})
    },

    receivedhistory(id) {
        return axios.get(url + '/business/received_transfer_history/' + {id})
    },

    addBizOwner(payload) {
        return axios.post(url + '/business/owners/add', payload)
    },

    removeO(id) {
        return axios.delete(url + '/business/owners/remove/' + id)
    },

    updateO(payload) {
        return axios.post(url + '/business/owners/update/' + payload.id + '', payload )
    },

    uploadbizkyc(formData) {
        return axios.post(url + '/business/documents/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        } )
    },

    updatebizkyc(formData) {
        return axios.post(url + '/business/documents/update/' + formData.get('id') + '', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    businesskyc(business_id) {
        return axios.get(url + '/business/kyc/level/' + business_id)
    },

    setsecreteq(business_id) {
        return axios.post(url + '/business/set-secret-question', business_id)
    },

    setnewbizpin(payload) {
        return axios.post(url + '/business/pin/new', payload)
    },

    resetnewbizpin(payload) {
        return axios.put(url + '/business/pin/reset/' + payload.business_id + '', payload)
    },

    editbiz(formData) {
        return axios.post(url + '/business/edit', formData, {
            headers: {
                'Content-Type' : 'multiform/form-data'
            }
        })
    },

    paystaffs(payload) {
        return axios.post(url + '/business/pay-staff', payload)
    },

    ////////////////////////////// POS //////////////////////////////
    posrequest(payload) {
        return axios.post(url + '/pos-request', payload, {
            headers: {
                'Content-type': 'multipart/form-data'
            }
        })
    },

    agentdetails(user_id) {
        return axios.get(url + '/pos/statistics', {
            params: {
                user_id: user_id
            }
        })
    },

    agentterminals(user_id) {
        return axios.get(url + '/pos/merchant/terminal/' + user_id)
    },

    agenttransactions(user_id) {
        return axios.get(url + '/pos/merchant/transaction', {
            params: {
                user_id: user_id
            }
        })
    },

    assignteller(payload) {
        return axios.post(url + '/agent/teller', payload) 
    },

    terminaltransactions(payload) {
        return axios.get(url + '/pos/terminal/transactions', {
            params: {
                terminal_id: payload
            }
        })
    },

    filterpostransactions(payload) {
        if (payload.user_id) {
            return axios.get(url + '/pos/merchant/transaction', {
                params: payload
            })
        } else if (payload.terminal_id) {
            return axios.get(url + '/pos/terminal/transactions', {
                params: payload
            })
        }
    },

    allpostransactions(payload) {
        if (payload.user_id) {
            return axios.get(url + '/pos/transactions/all', {
                params: payload
            })
        }

        else if (payload.terminal_id) {
            return axios.get(url + '/pos/transactions/all', {
                params: payload
            })
        }

        else if (payload.target == 'all_transactions') {
            return axios.get(url + '/pos/transactions/all', {
                params: payload
            })
        }

        else if (payload.target == 'agent_transactions') {
            return axios.get(url + '/pos/transactions/all', {
                params: payload
            })
        }

        else if (payload.target == 'terminal_transactions') {
            return axios.get(url + '/pos/transactions/all', {
                params: payload
            })
        }

        else if (!payload.includes(['user_id', 'terminal_id'])) {
            return axios.get(url + '/pos/transactions/all', {
                params: {
                    filter: payload
                }
            })
        }
    },

    downloadpostransactionsreport(payload) {
        return axios.get(url + '/pos/transactions/all', {
            params: payload
        })
    },

    fundTerminalWallet(payload) {
        return axios.post(url + '/pos/fund/terminal', payload);
    },

    withdrawTerminalFund(payload) {
        return axios.post(url + '/pos/terminal/withdraw', payload);
    },

    //////////////////////////////////////// REMITTANCE /////////////////////////

    getallintbanks(payload) {
        return axios.get(url + '/in/banks', {
            params: payload
        })
    },

    ordercard(payload) {
        return axios.post(url + '/virtual/card/request', payload)
    },

    getcards(id) {
        return axios.get(url + '/savings/cards/' + id)
    },

    intFund(payload) {
       // if(payload.method == 'mpesa') {
            return axios.post(url + '/in/fund/mpesa', payload)
       // }
    }
}
