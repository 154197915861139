import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
    // Vuetify module configuration (https://go.nuxtjs.dev/config-vuetify)
    customVariables: ['@/assets/variables.scss'],
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#171B70',
                accent: colors.grey.darken3,
                secondary: '#C1A13D',
                info: colors.teal.lighten1,
                warning: colors.amber.base,
                error: colors.deepOrange.accent4,
                success: colors.green.accent3,
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },
});