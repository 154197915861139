import auth from '../../router/middleware/auth'

const protectedRoutes = [
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('./index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/accounts',
        name: 'Accounts',
        component: () => import('./accounts/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/bills',
        name: 'Bills',
        component: () => import('./bills/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/business',
        name: 'Business',
        component: () => import('./business/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/cards',
        name: 'Cards',
        component: () => import('./cards/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/fund',
        name: 'Fund',
        component: () => import('./fund/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/remittance',
        name: 'Remittance',
        component: () => import('./remittance/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/loan',
        name: 'Loan',
        component: () => import('./loan/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },
    {
        path: '/dashboard/loan/requests',
        name: 'Request',
        component: () => import('./loan/pages/requests'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },
    {
        path: '/dashboard/pos',
        name: 'Pos',
        component: () => import('./pos/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/pos/terminal/:terminal_id/details',
        name: 'TerminalDetails',
        component: () => import('./pos/terminalDetails'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/pos/teller',
        name: 'PosTeller',
        component: () => import('./pos/teller'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/savings',
        name: 'Savings',
        component: () => import('./savings/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/savings/:id',
        name: 'SelectedSavings',
        component: () => import('./savings/selected'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/business/:id',
        name: 'SelectedBusiness',
        component: () => import('./business/selected'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },
    {
        path: '/dashboard/business/:id/loans',
        name: 'SelectedBusinessLoan',
        component: () => import('./business/loan/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/business/:id/cards',
        name: 'BusinessCards',
        component: () => import('./business/businessCards'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/transactions',
        name: 'Transactions',
        component: () => import('./transactions/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/giveaway',
        name: 'Giveaway',
        component: () => import('./giveaway/index'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    },

    {
        path: '/dashboard/statement',
        name: 'Statement',
        component: () => import('../../components/transactions/statement'),
        meta: {
            middleware: [ auth ],
            layout: () => import('../../layouts/dashboard')
        }
    }
];

export default protectedRoutes;