import {RepositoryFactory as Repo} from "../../repository/RepositoryFactory";

const PR = Repo.get('request'); //PR =  Public Request

const state = {
    loans: [],
    loanCount: 0,
    loanRequests: {},
    loanRequestCount: 0,
    userRequests: {},
    userRequestCount: 0,
    loan: {},
    userRequest: {},
    cards: [],
    card: {},
    kycStatus: false,
    loading: false,
    residentialStatuses: [],
    employmentStatuses: [],
    educationalQualifications: [],
    monthlyIncomes: [],
    loanAccount: {},
    loanBalance: 0,
    loanDetails: {},
    banks: [],
    idcards: [],
    idcard: {},
    guarantors: [],
    paystackkeys: {},
};

const getters = {
    loans: (state) => state.loans,
    loanCount: (state) => state.loanCount,
    loanRequests: (state) => state.loanRequests,
    loanRequestCount: (state) => state.loanRequestCount,
    userRequests: (state) => state.userRequests,
    userRequestCount: (state) => state.userRequestCount,
    loan: (state) => state.loan,
    kycStatus: (state) => state.kycStatus,
    loanAccount: (state) => state.loanAccount,
    loanBalance: (state) => state.loanBalance,
    loading: (state) => state.loading,
    residentialStatuses: (state) => state.residentialStatuses,
    employmentStatuses: (state) => state.employmentStatuses,
    educationalQualifications: (state) => state.educationalQualifications,
    monthlyIncomes: (state) => state.monthlyIncomes,
    loanDetails: (state) => state.loanDetails,
    cards: (state) => state.cards,
    card: (state) => state.card,
    idcards: (state) => state.idcards,
    idcard: (state) => state.idcard,
    banks: (state) => state.banks,
    guarantors: (state) => state.guarantors,
    paystackkeys: (state) => state.paystackkeys,
};

const mutations = {
    storeLoans: (state, payload) => {
        state.loans = payload.data;
        state.loanCount = payload.total;
    },
    storeLoanRequests: (state, payload) => {
        state.loanRequests = payload.data;
        state.loanRequestCount = payload.total;
    },
    storeUserRequests: (state, payload) => {
        state.userRequests = payload.data;
        state.userRequestCount = payload.total;
    },
    storeLoanBalance: (state, payload) => state.loanBalance = payload,
    storeKycStatus: (state, payload) => state.kycStatus = payload.state,
    setLoading: (state, payload) => state.loading = payload,
    storeIncomes: (state, payload) => state.monthlyIncomes = payload,
    storeQualifications: (state, payload) => state.educationalQualifications = payload,
    storeEmployments: (state, payload) => state.employmentStatuses = payload,
    storeResidential: (state, payload) => state.residentialStatuses = payload,
    storeLoanAccount: (state, payload) => state.loanAccount = payload,
    storeLoanDetails: (state, payload) => state.loanDetails = payload,
    clearLoanDetails: (state) => {
        state.loanDetails = {};
    },
    storeCards: (state, payload) => state.cards = payload,
    storeCard: (state, payload) => {
        state.card = payload;
        state.cards.push(payload);
    },
    storeBanks: (state, payload) => state.banks = payload,
    storeIdCards: (state, payload) => state.idcards = payload,
    storeGuarantors: (state, payload) => state.guarantors = payload,
    storePaystack: (state, payload) => state.paystackkeys = payload,
};

const actions = {
    // userLoans({}, {user_id, search, filter, start, end, page}) {
    //     return PR.fetchUserLoans(user_id, filter, search, start, end, page)
    // },
    userLoans({commit}, {owner_id, search, filter, start, end, page}) {
        PR.fetchUserLoans(owner_id, filter, search, start, end, page).then(res => {
            commit('storeLoans', res.data.loans);
            commit('storeLoanBalance', res.data.loan_balance);
            commit('storePaystack', res.data.paystack);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        }).finally(() => {
            //console.log('data loaded');
        })
    },
    userLoanRequests({commit}, {owner_id, search, start, end, page}) {
        PR.fetchUserLoanRequests(owner_id, search, start, end, page).then(res => {
            commit('storeLoanRequests', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    userAccountRequests({commit}, {owner_id, search, start, end, page}) {
        PR.fetchUserAccountRequests(owner_id, search, start, end, page).then(res => {
            commit('storeUserRequests', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    deleteLoan({}, id) {
        return PR.destroyLoan(id);
    },
    saveLoanRequest({}, payload) {
        return PR.createLoanRequest(payload);
    },
    saveUserRequest({}, payload) {
        return PR.createUserRequest(payload);
    },
    getKycStatus({}, user_id) {
        return PR.kycstatus(user_id);

        //     .then(res => {
        //     commit('storeKycStatus', res.data)
        // }).catch(err => {
        //     this._vm.$toast.error(err.response.data.message);
        // })
    },
    allResidential({commit}) {
        PR.getAllResidentialStatuses().then(res => {
            commit('storeResidential', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    allIncomes({commit}) {
        PR.getAllMonthlyIncomes().then(res => {
            commit('storeIncomes', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    allEmployments({commit}) {
        PR.getAllEmploymentStatuses().then(res => {
            commit('storeEmployments', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    allQualifications({commit}) {
        PR.getAllEducationalQualifications().then(res => {
            commit('storeQualifications', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    saveLoanAccount({}, data) {
        return PR.createLoanAccount(data);
    },
    updateLoanAccountOnBoarding({commit}, {user_id, data}) {
        PR.AccountOnboarding(user_id, data).then(res => {
            commit('storeLoanAccount', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    saveGuarantor({commit}, data) {
        PR.createLoanGuarantor(data).then(res => {
            commit('storeGuarantors', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        });
    },
    deleteGuarantor({commit}, id) {
        PR.deleteLoanGuarantor(id).then(res => {
            commit('storeGuarantors', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        });
    },
    fetchGuarantors({commit}, user_id) {
        PR.getLoanGuarantors(user_id).then(res => {
            commit('storeGuarantors', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    fetchLoanAccount({}, user_id) {
        return PR.showLoanAccount(user_id);
    },
    calculateLoan({}, data) {
        return PR.calculateLoanDetails(data);
    },
    createLoan({}, data) {
        return PR.createLoan(data);
    },
    walletRepayment({}, payload) {
        return PR.walletRepayLoan(payload.id, payload.data);
    },
    cardRepayment({}, payload) {
        return PR.cardRepayLoan(payload.id, payload.data);
    },
    newCardRepayment({}, payload) {
        return PR.newCardRepayLoan(payload.id, payload.data);
    },
    allUserPaymentCards({commit}, owner_id) {
        PR.getUserPaymentCards(owner_id).then(res => {
            commit('storeCards', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    allBanks({commit}) {
        PR.cardBankList().then(res => {
            commit('storeBanks', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
    initCardSave({}, {id, data}) {
        return PR.initLoanCard(id, data);
    },
    onLoanCallback({}, {id, reference}) {
        return PR.loanCardCallback(id, reference);
    },
    savePaymentCard({}, data) {
        return PR.createPaymentCard(data);
    },
    allIdCardTypes({commit}) {
        PR.getAllIdCardTypes().then(res => {
            commit('storeIdCards', res.data);
        }).catch(err => {
            this._vm.$toast.error(err.response.data.message);
        })
    },
};

export default {
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}