import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Protected Request


const actions = {
  async getallbanks() {
    const res = await PR.getallbanks()

    return res
  },

  async getallintbanks({}, { country }) {
    let payload = { country }
    const res = await PR.getallintbanks(payload)

    return res
  },

  async wallettowallet({},{ user_id, pin, amount, account_number, description }) {
    let payload = { user_id, pin, amount, description, account_number }
    const res = await PR.wallettowallet(payload)

    return res
  },

  async resolvewalletid({}, { wallet_id }) {
    let payload = wallet_id
    const res = PR.resolvewalletid(payload)

    return res
  },

  async savebeneficiary({}, {user_id, account_type, account_number}) {
    let payload = {user_id, account_type, account_number}
    const res = await PR.savebeneficiary(payload)

    return res
  },

  async resolveaccount({}, { account_number, bank_code }) {
    let payload = { account_number, bank_code }
    const res = await PR.resolveaccount(payload)

    return res
  },

  async transfertobank({}, { amount, recipient_account, bank_code, transfer_type, reason, user_id, pin, receiver_account_name, bank_name }) {
    let payload = { amount, recipient_account, bank_code, transfer_type, reason, user_id, pin, receiver_account_name, bank_name }
    const res = await PR.transfertobank(payload)

    return res
  },

  async settransactionpin({},{ transaction_pin, user_id }) {
    const res = await PR.settransactionpin( transaction_pin, user_id)

    return res
  },

  async updatetransactionpin({}, form) {
    const data = await PR.updatetransactionpin(form)

    return data
  },

  async beneficiaries({}, { user_id }) {
    const res = await PR.beneficiaries(user_id)

    return res
  },

  async multiplewalletstransfer({}, data) {
    let payload = { 
      wallets: data.wallets, 
      pin: data.pin 
    }
    const res = PR.multiplewalletstransfer(payload)

    return res
  },

  async multiplebanktransfer({}, data) {
    let payload = { 
      accounts: data.accounts,
      pin: data.pin  
    }
    const res = PR.multiplebanktransfer(payload)

    return res
  },

  async sendchangepinotp({}, form) {
    const res = await PR.sendchangepinotp(form)

    return res
  }
}

export default {
  namespaced: true,
  actions
}
