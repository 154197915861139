<template>
	<v-app>
		<!-- <v-main 
			:class="$route.name == 'Login' || 
			$route.name == 'Register' ||
			$route.name == 'ForgotPassword' ? 'layout-bg' : ''">
			<router-view/>
		</v-main> -->
    <v-main>
      <component :is="layout">
        <router-view />
      </component>
      <v-idle
        v-if="env !== 'development'"
        style="display: none;"
        @idle="onidle"
        :loop="true"
        :wait="5"
        :duration="250" />
    </v-main>
    <!-- <iframe src="" id="shareAuth" frameborder="0"></iframe> -->
	</v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      env: process.env.NODE_ENV
    }
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'div')  
    }
  },

  methods: {
    onidle() {
      if (this.$store.state.auth.token) {
        this.$toast.error('Session timeout!');
        this.$store.dispatch('auth/logout');
      }
    },
  }
};
</script>
<style lang="scss">
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important
  }
</style>
