import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth"
import bills from "./modules/bills"
import transfer from "./modules/transfer"
import savings from "./modules/savings"
import business from "./modules/business"
import cards from "./modules/cards"
import loan from "./modules/loan"
import pos from './modules/pos'
import remittance from './modules/remittance'
import SecureLS from 'secure-ls'
import createPersistedState from 'vuex-persistedstate'

let ls = new SecureLS({
    encodingType: 'aes',
    isCompression: false,
    encryptionSecret: 'transave'
})

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    bills,
    transfer,
    savings,
    business,
    cards,
    loan,
    pos,
    remittance
  },
  plugins: [
    createPersistedState({
      key: "transave",
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
})
