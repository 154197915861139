import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Protected Request

const actions = {
    async posrequest(_, formData) {
        return await PR.posrequest(formData);
    },

    async agentdetails(_, user_id) {
        return await PR.agentdetails(user_id);
    },

    async agentterminals(_, user_id) {
        return await PR.agentterminals(user_id);
    },

    async agenttransactions(_, user_id) {
        return await PR.agenttransactions(user_id);
    },

    async assignteller(_, payload) {
        return await PR.assignteller(payload);
    },

    terminaltransactions(_, terminal_id) {
        return PR.terminaltransactions(terminal_id);
    },

    async filtertransactions(_, payload) {
        return await PR.filterpostransactions(payload);
    },

    async filter_agent_transactions(_, payload) {
        return await PR.allpostransactions(payload);
    },

    async filter_terminal_transactions(_, payload) {
        return await PR.allpostransactions(payload);
    },

    async downloadreport(_, payload) {
        return await PR.downloadpostransactionsreport(payload);
    },

    async fund_terminal(_, payload) {
        return await PR.fundTerminalWallet(payload);
    },

    async withdraw_fund(_, payload) {
        return await PR.withdrawTerminalFund(payload);
    },
};

export default {
    namespaced: true,
    actions
}