import { RepositoryFactory as Repo} from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Protected Request

const actions = {
  async buyairtime({}, { phone, email, amount, service_id, transaction_pin }) {
    let payload = {
      phone, email, amount, service_id, transaction_pin
    };
    const res = await PR.buyairtime(payload);

    return res
  },

  async buydata({}, { service_id, data_bundles_id, phone, amount, transaction_pin, payment_ref, email }) {
    let amount_paid = amount
    let payload = {
      service_id, data_bundles_id, phone, amount, amount_paid, transaction_pin, payment_ref, email
    }
    const res = await PR.buydata(payload);

    return res
  },

  async databundles({}, { id }) {
    const res = await PR.databundles(id);

    return res
  },

  async tvplans({}, { id }) {
    const res = await PR.tvplans(id);

    return res
  },


  async verifycable({}, form) {
    let payload = {
      service_id: form.service_id,
      smartcard_num: form.smartcard_num,
      email: form.email,
      provider: form.provider,
      package: form.package,
      packageID: form.packageID,
      amount: form.amount,
      phone: form.phone
    }
    const res = await PR.verifycable(payload);

    return res
  },

  async buycable({}, { transaction_pin, user_id, token, amount_paid }) {
    let payload = { transaction_pin, user_id, token, amount_paid };
    const res = await PR.buycable(payload);
    console.log(res)
    return res
  },

  async verifypower({}, { stateID, state, disco, meterType, meter_num, amount, email, phone }) {
    let payload = {
      stateID, state, disco, meterType, meter_num, amount, email, phone
    };
    const res = await PR.verifypower(payload);

    return res
  },

  async buypower({}, { user_id, pin, token, amount_paid }) {
    let payload = { user_id, pin, token, amount_paid };
    const res = await PR.buypower(payload);

    return res
  },

  async states() {
    const res = await PR.states();

    return res
  },

  async power_transactions({}, user_id) {
    const res = await PR.power_transactions(user_id);

    return res
  },

  async data_transactions({}, user_id) {
    const res = await PR.data_transactions(user_id);

    return res
  },

  async airtime_transactions({}, user_id) {
    const res = await PR.airtime_transactions(user_id);

    return res
  },

  async tv_transactions({}, user_id) {
    const res = await PR.tv_transactions(user_id);

    return res
  }
};

export default {
  namespaced:true,
  // state,
  // getters,
  // mutations,
  actions
}
