import { RepositoryFactory as Repo } from "../../repository/RepositoryFactory";
const PR = Repo.get('request'); //PR =  Public Request
import router from '../../router'

const state = {}

const mutations = {}

const getters = {}

const actions = {
    async ordercard({}, { user_id, expiry_date }) {
        let payload = { user_id, expiry_date }
        const res = await PR.ordercard(payload)

        return res
    },

    async getcards({}, { id }) {
        const res = await PR.getcards(id)

        return res
    },

    async intFund({}, { method, amount, currency, email }) {
        let payload = { method, amount, currency, email }
        const res = await PR.intFund(payload)

        return res
    }
}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}